import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
import { AppWrap } from "../../wrapper";

import "./About.scss";

// const abouts = [
//   {
//     title: "Full Stack Development",
//     description: "With knowledge of the latest technolgies and a degree in Computer Science, I feel I can provide end to end expertise to help build or scale any application. APIs, Databases and Front End expertise",
//     imgUrl: images.about01,
//   },
//   {
//     title: "Custom Solutions",
//     description: "Logistics, inventory count, time tracking , online booking, I have got you covered with a custom solution. This could be a critical step to taking your business productivity to the next level.",
//     imgUrl: images.about04,

//   },
//   {
//     title: "Web Design",
//     description: "Have a brick and mortar business that you need to expand digitally to grow sales and boost marketing? Looking to start a blog to interact with your customer base? I can help with a full solution for all your technology needs.",
//     imgUrl: images.about02,

//   },
//   {
//     title: "SEO Optimization",
//     description: "Let's face it , traffic is everything these days. I will make sure by using SEO methods and best practices to help drive as much traffic to your site as possible. I can also help you create a social media presence.",
//     imgUrl: images.about03,

//   },
// ];
const About = () => {

  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type== "abouts"]';
    client.fetch(query)
    .then((data) => setAbouts(data));
  }, [])
  

  return (
    <>
      <h2 className="head-text">
        I Know That <span>Good Software</span><br /> means <span>Good Business</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opascity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
          <img src={urlFor(about.imgUrl)} alt={about.title} />
          <h2 className="bold-text" style={{marginTop : 20}}>{about.title}</h2>
          <p className="p-text" style={{marginTop : 10}}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(About, 'about');
