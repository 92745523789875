import React from "react";
import { BsLinkedin, BsMailbox2 , BsFileCodeFill } from "react-icons/bs";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a target="__blank" href="https://www.linkedin.com/in/jashankhela/"><BsLinkedin /></a> 
      </div>
      <div>
      <a target="__blank" href="https://my.visualcv.com/jashan-khela/"> <BsFileCodeFill /></a>
      </div>
      <div>
      <a target="__blank" href="mailto:jashan.khela@gmail.com"><BsMailbox2 /></a>
      </div>
    </div>
  );
};

export default SocialMedia;
